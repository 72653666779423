<template>
  <b-row>
    <b-col sm="12">
      <b-card>
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Ventas de Cursos</h4>
            <div class="small text-muted">Administración de todos las ventas de cursos recibidas</div>
          </b-col>
          
          <b-col sm="5">
            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>
          </b-col>                    
        </b-row>
      </b-card>
      
      
      <b-card id="list-orders" v-if="table.mostrarFiltros">
        <b-row >
          <b-col sm="10">            
            <b-row>            
              <b-col md="2" class="pr-0">
                <b-form-input type="date" size="md" v-model="filters.date_start" placeholder="Fecha Desde"></b-form-input>
              </b-col>
              <b-col md="2" class="pl-0">
                <b-form-input type="date" size="md" v-model="filters.date_end" placeholder="Fecha Hasta"></b-form-input>
              </b-col>                 
              <!--
              <b-col sm="2">
                <v-select :options="arr.filters.sellers" v-model="filters.sellers" placeholder="Vendedores" :multiple="true" :select-on-tab="true"></v-select>
              </b-col>                        
              -->
              <b-col sm="2">
                <v-select :options="arr.filters.customers" v-model="filters.customers" placeholder="Clientes" :multiple="true" :select-on-tab="true"></v-select>
              </b-col>    
              <b-col sm="2">
                <b-button variant="outline-dark" @click="filterOrders()" size="sm">
                  <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                  Filtrar
                </b-button>
              </b-col>
            </b-row>
          </b-col>         
        </b-row>              
      </b-card>
      
      <b-row>
        <b-col lg="8" class="pr-0" id="view_list_orders">
          <b-card>
            <b-row>
              <b-col>
                <b-form-group>
                  <b-input-group>
                    <b-form-input type="text" 
                                  placeholder="Ingrese su busqueda para filtrar la grilla" 
                                  v-model="table.filter"
                                  size="sm">
                    </b-form-input>              
                  </b-input-group>
                </b-form-group>            
              </b-col>
              <b-col>
                <!--
                <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Pedido">
                  <i class="fa fa-plus"></i> Agregar
                </b-button>
                -->
              </b-col>
              <b-col sm="12">
                <b-table class="mb-0 table-orders-custom"
                        ref="table"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="table.items"
                        :fields="table.fields"
                        :filter="table.filter"
                        :current-page="table.currentPage"
                        :per-page="table.perPage"
                        selectable
                        select-mode="single"
                        @row-selected="onRowSelected"  
                        :busy="table.isBusy"                      
                        v-if="table.items.length || table.isBusy">                       
                    
                    <template v-slot:table-colgroup="scope">
                      <col
                        v-for="field in scope.fields"                    
                        :key="field.key"
                        :style="{ width: field.width }"
                      >
                    </template> 

                    <template v-slot:table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Cargando...</strong>
                      </div>
                    </template>

                    <template v-slot:cell(id)="data">
                      <b>#{{data.item.id}}</b>
                    </template>
 
                    <template v-slot:cell(date)="data">
                      {{moment(data.item.date).format('DD/MM/YYYY')}}
                    </template>

                    <!--
                    <template v-slot:cell(sellers_id)="data">
                      <div v-if="data.item.sellers_id">
                        <b-avatar :src="data.item.seller.staff.image"
                                  v-if="data.item.seller.staff.image">
                        </b-avatar>
                        <b-avatar v-else                                                                         
                                  variant="dark">
                        </b-avatar>     
                        <b-icon icon="blank"></b-icon>
                        <b :title="'VENDEDOR: ' + data.item.seller.staff.name">
                          {{data.item.seller.staff.name}}
                        </b> 
                      </div>                       
                    </template>
                    -->
                    
                    <template v-slot:cell(customers_id)="data">       
                      <div v-if="data.item.customer">                        
                        <b-avatar :src="data.item.customer.image"
                                  v-if="data.item.customer.image">
                        </b-avatar>
                        <b-avatar v-else 
                                  icon="building"                                            
                                  variant="dark">
                        </b-avatar>     
                        &nbsp;<b>{{data.item.customer.name}}</b>   
                        <b-icon icon="circle-fill" v-if="!data.item.customer.active" class="ml-2" variant="danger" v-b-tooltip.hover title="Cliente INACTIVO" />               
                      </div>
                    </template>

                    <template v-slot:cell(total)="data">       
                      <div v-if="data.item.status!='in_process'">
                        <div v-if="data.item.paid" class="order-total-table-paid">
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.origin_currency_code}).format(data.item.total)}}
                        </div>
                        <div v-else class="order-total-table-nopaid">
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.origin_currency_code}).format(data.item.total)}}
                        </div>
                      </div>
                    </template>

                    <template v-slot:cell(paid_info)="data">
                      <div v-if="data.item.paid_info" v-b-tooltip.hover :title="data.item.paid_info">
                        <b-icon icon="cash-stack"></b-icon>
                      </div>                        
                    </template>

                    <template v-slot:cell(f_action)="data">
                      <b-dropdown right 
                                  text="Acción" 
                                  size="sm" 
                                  variant="outline-dark" 
                                  class="pull-right" 
                                  @show="setConfigTableOrdersShow"
                                  @hide="setConfigTableOrdersHide">
                        
                        <b-dropdown-header>Pagos</b-dropdown-header>
                        <b-dropdown-item @click="changePaid(data.item)" v-if="showPaid(data.item)">
                          <b-icon icon="cash"></b-icon> Forzar pagado 
                        </b-dropdown-item>  
                        <b-dropdown-item @click="changePaid(data.item)" v-if="!showPaid(data.item)">
                          <b-icon icon="x-circle"></b-icon> Cancelar pago 
                        </b-dropdown-item>    

                        <b-dropdown-header>Acciones</b-dropdown-header>                         
                        <b-dropdown-item @click="remove(data.item)">
                          <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col lg="4" id="contain_details_items">
          <div id="details_items">
            <b-card>            
              <b-row>              
                <b-col md="12">
                  
                  <!-- PEDIDO SELECCIONADO -->
                  <b-row class="mb-3">
                    <b-col md="6">
                      <div class="crud-orders-title" v-if="itemSelected">
                        <div class="crud-orders-title">
                          <span class="crud-orders-code">
                            #{{this.itemSelected.id}}                             
                          </span><br>
                          <span v-if="itemSelected.customer">
                            {{this.itemSelected.customer.name}}                          
                          </span>                          
                        </div>
                      </div>
                    </b-col>
                    <b-col md="6">
                      <b-row>
                        <b-col md="12">
                          <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="hideSub()" v-b-tooltip.hover title="Ocultar Detalle">
                            <i class="fa fa-window-close"></i>
                          </b-button>
                        </b-col>                      
                      </b-row>                   
                    </b-col>
                  </b-row>

                  <!-- DETALLE -->                
                  <b-row>
                    <b-col md="12">   
                      <b-link v-if="tableSub.items.length" 
                              @click="openDetail()"
                              class="pull-right">
                        Ver detalle completo
                      </b-link>
                                                             
                      <b-table  class="mb-0 table-orders-sub"
                                responsive
                                head-variant="dark"
                                :hover="true"
                                :small="true"
                                :fixed="true"
                                :items="tableSub.items"
                                :fields="tableSub.fields"                            
                                v-if="tableSub.items.length">   
                        
                        <template v-slot:table-colgroup="scope">
                          <col
                            v-for="field in scope.fields"                    
                            :key="field.key"
                            :style="{ width: field.width }"
                          >
                        </template>                         
                         
                        <template v-slot:cell(course)="row">
                          <div v-html="row.item.courses.name"></div>
                        </template>

                      </b-table>                      
                      <b-alert v-else variant="warning" show>No se seleccionó ningún pedido</b-alert>

                    </b-col>                    
                  </b-row>
                </b-col>
              </b-row>
            </b-card>           
          </div>
        </b-col>
      </b-row>          

      <b-row>
        <b-col lg="8" class="pr-0" id="view_footer_orders">  
          <b-card>
            <b-row>
              <b-col>
                <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
                  <i class="fa fa-angle-double-left"></i>
                  Volver
                </b-button>                        
              </b-col>

              <b-col>
                <nav v-if="table.tablePaginate">
                  <b-pagination class="pull-right mb-0"
                                size="sm"
                                pills=""
                                :total-rows="table.tablePaginate.total"
                                :per-page="table.tablePaginate.per_page"
                                v-model="table.currentPage"
                                @input="filterOrders()" />
                </nav>
              </b-col>          
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD DETAILS -->
      <b-modal v-model="modal.detail.active"
              header-bg-variant="dark"
              header-text-variant="white"
              size="lg">
        <div slot="modal-header">
          {{this.modal.detail.title}}
        </div>

        <b-row>
          <b-col sm="12">

            <b-tabs active-nav-item-class="font-weight-bold text-uppercase text-dark">

              <b-tab title="General">
                <table class="table table-hover table-sm" v-if="itemSelected">
                  <tbody>
                    <tr>
                      <td class="crud-orders-title-table-custom align-middle">Número</td>
                      <td class="crud-orders-value-table-custom align-middle">
                        <b>#{{this.itemSelected.id}}</b>
                      </td>
                    </tr> 
                    <tr>
                      <td class="crud-orders-title-table-custom align-middle">Fecha</td>
                      <td class="crud-orders-value-table-custom align-middle">
                        {{moment(this.itemSelected.date).format('DD MMMM YYYY')}}
                      </td>
                    </tr>                      
                    <tr v-if="itemSelected.customer">
                      <td class="crud-orders-title-table-custom align-middle">Cliente</td>
                      <td class="crud-orders-value-table-custom align-middle">
                        {{this.itemSelected.customer.name}}
                      </td>
                    </tr> 
                    <tr v-if="itemSelected.status!='in_process'">
                      <td class="crud-orders-title-table-custom align-middle">SubTotal</td>
                      <td class="crud-orders-value-table-custom align-middle">                        
                        {{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelected.origin_currency_code}).format(this.subtotalItemSelected)}}
                      </td>
                    </tr>                    
                    <tr v-if="parseFloat(itemSelected.discounts_price)">
                      <td class="crud-orders-title-table-custom align-middle">Descuento</td>
                      <td class="crud-orders-value-table-custom align-middle">                        
                        <div>
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelected.origin_currency_code}).format(this.itemSelected.discounts_price)}}
                        </div>
                      </td>
                    </tr>                    
                    <tr>
                      <td class="crud-orders-title-table-custom align-middle">Total</td>
                      <td class="crud-orders-value-table-custom align-middle">    
                        <div v-if="itemSelected.paid" class="order-total-table-paid">                    
                          <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelected.origin_currency_code}).format(this.itemSelected.total)}}</b>
                        </div>
                        <div v-else class="order-total-table-nopaid">
                          <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelected.origin_currency_code}).format(this.itemSelected.total)}}</b>
                        </div>
                      </td>
                    </tr>  
                    <tr v-if="itemSelected.paid_info">
                      <td class="crud-orders-title-table-custom align-middle">Observaciones Pago</td>
                      <td class="crud-orders-value-table-custom align-middle">                        
                        {{this.itemSelected.paid_info}}
                      </td>
                    </tr>                                                                                                 
                  </tbody>
                </table>
              </b-tab>
              <b-tab title="Items">                
                <b-table class="mb-0 table-full-detail-orders"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="tableDetail.items"
                        :fields="tableDetail.fields"
                        v-if="tableDetail.items.length">                       
                    
                  <template v-slot:table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"                    
                      :key="field.key"
                      :style="{ width: field.width }"
                    >
                  </template>           

                  <template v-slot:cell(code)="data">                           
                    <div v-html="data.item.courses.code"></div>
                  </template>

                  <template v-slot:cell(course)="data">                           
                    <div v-html="data.item.courses.name"></div>
                  </template>

                  <template v-slot:cell(total)="data">                           
                    {{Intl.NumberFormat('es-AR',{style:'currency',currency:itemSelected.origin_currency_code}).format(data.item.total)}}                    
                  </template>

                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-tab>
            </b-tabs>

          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.detail.active=false">Cancelar</b-button>                   
        </div>
      </b-modal>      
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import ErrorToken from '@/handler/errorToken'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import FindObject from '@/components/inc/find/findObject'  
  import Param from '@/config/parameters'
  import moment from 'moment'

  export default {
    components: {
      FindObject,
    },    
    data: () => {
      return {      
        access: {
          module_id: Modules.PEDIDOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudOrdersCourses',
          elements: {}
        },         
        table : {
          items: [],
          fields: [],
          filter: null,
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 0,
          isBusy: false,
          tablePaginate: null,          
        },
        tableSub : {
          items: [],
          fields: []
        },  
        tableDetail : {
          items: [],
          fields: []
        },                   
        crud: {
          form: {
            id: 0,                  
          }, 
          paid: {
            id: 0,
            paid: false,
            paid_info: '',            
          }                                                           
        },
        modal: {
          form: {
            active: false,
            title: ''
          },
          detail: {
            active: false, 
            title: ''
          },
          paid: {
            active: false,
            title: '',
          }                                                  
        },
        arr: {
          orders: [],  
          filters : {
            customers: [],
            sellers: [],                   
          },                       
        },   
        filters: {
          date_start: '',
          date_end: '',
          customers: null,
          sellers: null,
          source: 'course'
        },
        itemSelected: null,     
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {   
      this.loadFieldTable()      
      this.filterLoad()    
      this.filterOrders()       
      this.loadStyleConfig()
    },
    computed: {
      subtotalItemSelected() {
        if(this.itemSelected) {
          return parseFloat(this.itemSelected.total) - + parseFloat(this.itemSelected.discounts_price)
        } else {
          return 0
        }
      }
    },    
    methods: {
      loadStyleConfig () {        
        var view_list_orders = document.getElementById('view_list_orders')
        var view_footer_orders = document.getElementById('view_footer_orders')

        var padre = document.getElementById('contain_details_items')
        var hijo = document.getElementById('details_items')  
              
        hijo.style.width = padre.clientWidth - 45 + 'px'

        if(window.innerWidth < 991) {
          hijo.style.width = '100%'
          hijo.style.position = "relative"
          hijo.style.right = 'auto'

          view_list_orders.classList.remove('pr-0')
          view_footer_orders.classList.remove('pr-0')
        } else {          
          view_list_orders.classList.add('pr-0')
          view_footer_orders.classList.add('pr-0')
        }        
      },
      loadFieldTable () {
        // TABLE ORDERS
        this.table.fields.push({key: 'id', label: 'Nº', sortable: true, class:"align-middle text-center", width:"70px"})
        this.table.fields.push({key: 'date', label: 'Fecha', class:"align-middle", width:"80px"})                      
        this.table.fields.push({key: 'customers_id', label: 'Cliente', class:"align-middle", width:"230px"})              
        this.table.fields.push({key: 'total', label: 'Total', class:"align-middle text-right", width:"90px"})                              
        this.table.fields.push({key: 'paid_info', label: '', class:"align-middle text-center", width:"25px"})                        
        this.table.fields.push({key: 'f_action', label:'', class:"align-middle", width:"60px"})

        // TABLE DETALLE
        this.tableSub.fields.push({key: 'course', label: 'Curso', class:"align-middle", width:"150px"})                

        // TABLE DETALLE COMPLETO        
        this.tableDetail.fields.push({key: 'code', label: 'Código', class:"align-middle", width:"50px"})                
        this.tableDetail.fields.push({key: 'course', label: 'Curso', class:"align-middle", width:"250px"})                    
        this.tableDetail.fields.push({key: 'total', label: 'Total', class:"align-middle", width:"80px"})                
        

      },
      getRowCount (items) {
        return items.length
      },
      onRowSelected(item) {         
        this.arr.orders.forEach((element, index) => {
          this.table.items[index]._showDetails = false
          if(item.length){
            if(element.id == item[0].id) {  
              this.openSub(element)                             
            }
          }
        })
      },   
      setConfigTableOrdersShow() {
        var arrTable = document.getElementsByClassName('table-orders-custom')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = '290px'
        }        
      },
      setConfigTableOrdersHide() {
        var arrTable = document.getElementsByClassName('table-orders-custom')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = 'auto'
        }        
      },      
      setConfigTableSubShow() {
        var arrTable = document.getElementsByClassName('table-orders-sub')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = '210px'
        }        
      },
      setConfigTableSubHide() {
        var arrTable = document.getElementsByClassName('table-orders-sub')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = 'auto'
        }        
      },     

      remove(item) {
        this.crud.form.id = item.id

        this.$bvModal.msgBoxConfirm('¿Desea borrar la venta #' + this.crud.form.id + '?', {
          title: 'Borrar Venta',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarPedido(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.filterOrders()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
     
      filterLoad() {
        this.filterLoadCustomers()          
        this.filterLoadSellers()   
        this.filters.date_start = moment().subtract(30, 'days').format('YYYY-MM-DD') 
        this.filters.date_end = moment().format('YYYY-MM-DD') 
      },
      filterLoadCustomers() {
        var result = serviceAPI.obtenerClientes()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.customers = []
          data.forEach(element => {         
            if(element.code) {
              this.arr.filters.customers.push({ code: element.id, label: element.code + ' | ' + element.name })            
            } else {
              this.arr.filters.customers.push({ code: element.id, label: element.name })            
            }                 
          });          
        })   
      },
      filterLoadSellers() {
        var result = serviceAPI.obtenerVendedores()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.sellers = []
          data.forEach(element => {                            
            this.arr.filters.sellers.push({ code: element.id, label: element.name })            
          });          
        })   
      },       
      filterOrders(forceOpenSub=false) {
        this.table.isBusy = true
        var result = serviceAPI.filtrarPedido(this.filters, this.table.currentPage)        
        result.then((response) => {          
          var data = response.data
          this.table.tablePaginate = data
          this.table.items = data.data
          this.arr.orders = data.data
          
          this.table.items.forEach(element => {
            if(element.status == 'cancel') {
              element._rowVariant = 'danger'                          
            }
            
            if(forceOpenSub) {
              if(element.id == this.itemSelected.id) {
                this.openSub(element)
              }              
            }                
          });

          if(this.table.rowSelected!=null) {            
            this.table.items[this.table.rowSelected]._showDetails = true                   
          }         

          if(this.$refs.table) {            
            this.$refs.table.$forceUpdate()
          }          
          
          this.table.isBusy = false
        })           
        .catch(error => {            
          this.table.isBusy = false  
          this.$awn.alert(ErrorToken.valid(error));
        })  
      },     
     
      openSub(item) {      
        this.itemSelected = item                        
        this.tableSub.items = item.details_courses           
        this.loadStyleConfig()
      },    
      hideSub() {
        this.tableSub.items=[]
        this.itemSelected = null
      },      
            
      openDetail() {              
        this.tableDetail.items = this.itemSelected.details_courses

        this.modal.detail.title = "Detalle de la venta"
        this.modal.detail.active = true
      },

      showPaid(item) {
        if(item.paid) {
          return false
        } else {
          return true
        }
      },
      changePaid(item) {
        var msj = ''
        if(item.paid) {
          msj = '¿Continuar con la cancelación del pago de la venta #' + item.id + '?'
        } else {
          msj = '¿Desea forzar el pago de la venta #' + item.id + '?'
        }

        this.$bvModal.msgBoxConfirm(msj, {
          title: 'Estado Pago',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {        
            let loader = this.$loading.show();

            this.crud.paid.id = item.id
            
            if(item.paid) {
              this.crud.paid.paid = false
              this.crud.paid.paid_info = 'Se forzó la cancelación del pago desde el sistema'          
            } else {
              this.crud.paid.paid = true
              this.crud.paid.paid_info = 'Se forzó el pago desde el sistema'                        
            }
            
            var result = serviceAPI.cambiarPago(this.crud.paid);
            
            result.then((response) => {        
              loader.hide()
              this.filterOrders()
              this.$awn.success("Pago forzado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })  
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })                
      }      
    }
  }
</script>
<style>
  .crud-orders-title {
    font-size: 15px;
    font-weight: 600;
  }
  .crud-orders-code {
    color: gray;
  }   
  #details_items {
    position: fixed;
    right: 15px;    
  }     
  .table-orders-sub {
    overflow: auto;
    max-height: 350px;
  }
  .crud-orders-title-table-custom {
    color: #fff;
    background-color: #2f353a;
    border-color: #40484f;
    font-weight: 700;
    padding-right: 10px;
    width: 25%;
    text-align: right
  }
  .crud-orders-value-table-custom {
    padding-left: 5px;
  }  
  .table-full-detail-orders {
    overflow: auto;
    max-height: 350px;    
  }
  .order-total-table-paid {
    color: darkgreen;
    font-weight: 700;
  } 
  .order-total-table-nopaid {
    color: darkred;
    font-weight: 700;
  }  
</style>