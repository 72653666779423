import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtenerPedido() {
    var url = ConfigAPI.baseURL + "orders" + Session.getToken();
    return instance.get(url);
  },
  mostrarPedido(id) {
    var url = ConfigAPI.baseURL + "orders/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarPedido(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "orders" + Session.getToken();
    return instance.post(url,params);
  },
  editarPedido(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "orders/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarPedido(id) {
    var url = ConfigAPI.baseURL + "orders/"+ id + Session.getToken();
    return instance.delete(url);
  }, 
  filtrarPedido(data, page) {
    var params = data    
    var url = ConfigAPI.baseURL + "orders/filter/query" + Session.getToken() + "&page=" + page;
    return instance.post(url,params);
  },  
  cambiarEstadoPedido(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "orders/change/status" + Session.getToken();
    return instance.post(url,params);
  },
  cambiarPago(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "orders/change/paid" + Session.getToken();
    return instance.post(url,params);    
  },
  trackingPedido(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "orders/change/status/in_transport" + Session.getToken();
    return instance.post(url,params);        
  },
  changeNroOrderInternal(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "orders/change/nro_order_internal" + Session.getToken();
    return instance.post(url,params);    
  },
  sendEmailOrder(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "orders/send/email" + Session.getToken();
    return instance.post(url,params);    
  },  
  obtenerClientesByZonas(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "sellers/filter/customerByZone" + Session.getToken();
    return instance.post(url,params);     
  },

  obtenerConfiguracion() {
    var url = ConfigAPI.baseURL + "orders-configuration" + Session.getToken();
    return instance.get(url);
  },
  saveConfiguracion(data) {
    var params = data
    var url = ConfigAPI.baseURL + "orders-configuration/" + data.id + Session.getToken();
    return instance.post(url,params);
  },  

  obtenerClientes() {
    var url = ConfigAPI.baseURL + "customers" + Session.getToken();
    return instance.get(url);
  },
  mostrarCliente(id) {
    var url = ConfigAPI.baseURL + "customers/" + id + Session.getToken();
    return instance.get(url);
  },  
  obtenerVendedores() {
    var url = ConfigAPI.baseURL + "sellers" + Session.getToken();
    return instance.get(url);
  },  
  obtenerPuntosVentas() {
    var url = ConfigAPI.baseURL + "sales-points-sales" + Session.getToken();
    return instance.get(url);
  },    
  
  integratedManufactureSystemPedido(data) {
    var params = data.data
    var url = data.url;
    return instance.post(url,params);
  },
  cambiarEstadoPedidoEnPreparacion(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "orders/change/status/in_preparation" + Session.getToken();
    return instance.post(url,params);        
  },
  cambiarEstadoSistemaProduccion(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "orders/change/statusSystemManufacture" + Session.getToken();
    return instance.post(url,params);        
  }  

}

export default servicesAPI;
